<template>
  <div class="auth-wrapper auth-v1 align-start py-0">
    <div class="auth-inner">
      <v-card-text
        :style="{
          backgroundColor: '#222222',
        }"
      >
        <v-row class="px-0 py-1 ma-0">
          <v-col
            cols="12"
            class="py-1 px-0 d-flex justify-space-between align-center"
          >
            <div class="d-flex">
              <div class="d-flex flex-column ml-1">
                <div class="white--text d-flex align-center">
                  <h2 class="">
                    {{ t('Videos') }}
                  </h2>
                </div>
                <div>
                </div>
              </div>
            </div>

            <div>
              <v-btn
                v-if="!privateLanding"
                class="ma-0 py-2 px-0 no-bg-hover"
                style="
                  text-transform: inherit;
                  min-width: 60px;
                "
                text
                color="secondary"
                @click="onLogin()"
              >
                {{ t('login.login') }}
              </v-btn>

              <app-bar-user-avatar
                v-else
                class="ms-2"
                @logout="onLogout"
              />
            </div>
          </v-col>

          <v-col
            cols="12"
            class="pb-0 px-0"
          >
            <v-text-field
              v-model="search"
              :placeholder="t('facilities.search_facility')"
              :prepend-inner-icon="icons.mdiMagnify"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <facility-list
        class="my-3"
        :facilities="facilities"
        @selectFacility="onSelectFacility"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, onMounted } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency } from '@core/utils'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'

import useLogData from '@core/utils/useLogData'
import useVideoData from '@core/utils/useVideoData'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'
import FacilityList from './facility-list/FacilityList.vue'

import useFacilities from './useFacilities'

export default {
  components: {
    FacilityList,
    AppBarUserAvatar,
  },
  setup() {
    const { t } = useUtils()
    const { router } = useRouter()
    const { userData } = useCryptoJs()
    const { logData, updateLogData, clearLogData } = useLogData()
    const { videoData, updateVideoData, clearVideoData } = useVideoData()
    const {
      search,
      itemSelected,
      facilities,
      facilitiesLoaded,
      latitudeData,
      longitudeData,

      fetchFacilities,
    } = useFacilities()

    const privateLanding = ref(false)

    const onSelectFacility = async facility => {
      itemSelected.value = facility
      videoData.value.facility = facility
      await updateVideoData(videoData.value)

      if (itemSelected.value) {
        router.push({
          name: 'views-video',
          params: {
            facility: itemSelected.value.id,
            field: 'all',
            date: 'all',
            time: 'all',
          },
        })
      }
    }

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = null
      logData.value.redirect = 'facilities'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      privateLanding.value = false
    }

    onMounted(async () => {
      await localStorage.removeItem('matchCode')
      privateLanding.value = !!userData.value

      if (!privateLanding.value) {
        await clearLogData()
        await clearVideoData()
      }

      // await fetch('https://api.ipify.org/?format=json')
      //   .then(ipResponse => ipResponse.json())
      //   .then(async ipData => {

      //     await fetch(`https://ip-api.com/json/${ipData.ip}`)
      //       .then(locationResponse => locationResponse.json())
      //       .then(async locationData => {
      //         latitudeData.value = locationData.lat
      //         longitudeData.value = locationData.lon

      //         await fetchFacilities()
      //       })
      //   })

      await fetchFacilities()

      // This will open permission popup
      navigator.geolocation.getCurrentPosition(async position => {
        const { latitude } = position.coords
        const { longitude } = position.coords

        // Do something with the position
        latitudeData.value = latitude
        longitudeData.value = longitude
        await fetchFacilities()
      }, err => {
        console.error(err)
      })
    })

    return {
      // data
      privateLanding,
      userData,
      search,
      itemSelected,
      facilities,
      facilitiesLoaded,

      // methods
      formatCurrency,
      onSelectFacility,
      onLogin,
      onLogout,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      t,

      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
@import '@core/preset/preset/pages/landing.scss';
</style>
<style lang="scss" scoped>
  .absolute-crown {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .gold-border {
    border: 2px solid #FFCC00 !important;

  }

  .white-border {
    border: 2px solid #FFFFFF !important;
  }

  .app-content-container {
    padding: 0px !important;
  }

  .row {
    margin: 0;
  }
  .v-text-field--outlined {
    border-radius: 15px;
  }

  .v-text-field--outlined .v-input__control .v-input__slot {
    background-color: #333333 !important;
  }
</style>
