<template>
  <div>
    <v-row
      v-for="(item, index) in computedFacilities"
      :key="item.id"
      class="py-1 px-6 ma-0 cursor-pointer"
      :class="{'b-bottom': index !== facilities.length - 1}"
      @click="$emit('selectFacility', item)"
    >
      <v-col
        cols="12"
        class="py-2 px-0 d-flex justify-space-between align-center"
      >
        <facility-item :facility="item" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'

import FacilityItem from './FacilityItem.vue'

export default {
  name: 'FacilityList',
  components: {
    FacilityItem,
  },
  props: {
    facilities: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props) {
    const { t } = useUtils()

    const isDialogChangePlayers = ref(false)
    const isDialogAddGuest = ref(false)

    const computedFacilities = computed(() => props.facilities)

    return {
      // data
      isDialogChangePlayers,
      isDialogAddGuest,

      computedFacilities,

      // methods
      avatarText,

      // i18n
      t,
    }
  },
}
</script>
