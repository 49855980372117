import { ref, watch } from '@vue/composition-api'
import { getFacilitiesLocation, getFacilitiesActive } from '@api'

export default function useFacilities() {
  const search = ref('')
  const timerSearch = ref(null)
  const itemSelected = ref(0)
  const facilities = ref([])
  const facilitiesLoaded = ref([])
  const latitudeData = ref(null)
  const longitudeData = ref(null)

  // fetch data
  const fetchFacilities = async () => {
    try {
      let respLocation = null
      if (latitudeData.value && longitudeData.value) {
        respLocation = await getFacilitiesLocation(latitudeData.value, longitudeData.value)
      } else {
        respLocation = await getFacilitiesActive()
      }

      if (respLocation && respLocation.ok) {
        facilities.value = respLocation.data
        facilitiesLoaded.value = respLocation.data
      } else {
        facilities.value = []
        facilitiesLoaded.value = []
      }
    } catch (e) {
      console.error(e)
      facilities.value = []
      facilitiesLoaded.value = []
    }
  }

  const filterFacilities = facility => {
    if (
      facility.name.toLowerCase().includes(search.value.toLowerCase())
      || facility.short_address.toLowerCase().includes(search.value.toLowerCase())
    ) return true

    return false
  }

  const onSearch = () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(() => {
      const filtered = facilitiesLoaded.value.filter(facility => filterFacilities(facility))
      facilities.value = filtered
    }, 1000)
  }

  watch([search], () => {
    onSearch()
  })

  return {
    search,
    timerSearch,
    itemSelected,
    facilities,
    facilitiesLoaded,
    latitudeData,
    longitudeData,

    fetchFacilities,
  }
}
